/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {L} from "@src/style/mdxStyle.js";
import ReactCompareImage from "react-compare-image";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "limiting-luminosity-masks",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#limiting-luminosity-masks",
    "aria-label": "limiting luminosity masks permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Limiting luminosity masks"), "\n", React.createElement(_components.p, null, "One of the most popular among landscape photographers and simple at the same time techniques to work with luminosity masks it to limit them with groups."), "\n", React.createElement(_components.p, null, "You can build a very complicated effect with luminosity mask, but if you want to carefully control where exactly it will work you can wrap it in group and apply another mask on top."), "\n", React.createElement(_components.p, null, "Here how it works."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Create your effect limited by luminosity mask"), "\n"), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[0].publicURL,
    rightImage: props.data.mdx.frontmatter.images[1].publicURL
  }), "\n", React.createElement(_components.p, null, "Here I have created a color mask selecting greens and adjusted brightness, with the goal to make green specks in the water stand out more."), "\n", React.createElement(_components.ol, {
    start: "2"
  }, "\n", React.createElement(_components.li, null, "Wrap your effect into group with black mask"), "\n"), "\n", React.createElement(_components.p, null, "If you are using ARCMask you can just click on a black folder icon in the panel."), "\n", React.createElement(_components.ol, {
    start: "3"
  }, "\n", React.createElement(_components.li, null, "Paint on the mask with regular brush to reveal the effect where you want"), "\n"), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[1].publicURL,
    rightImage: props.data.mdx.frontmatter.images[2].publicURL
  }), "\n", React.createElement(_components.p, null, "What this simple technique gives you is even more control on how to limit desired effects with masks. I my practice, all professional landscape photographers are using this approach when working with luminosity masks."), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[0].publicURL,
    rightImage: props.data.mdx.frontmatter.images[2].publicURL
  }), "\n", React.createElement(_components.p, null, "Very simple but very powerful technique."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
